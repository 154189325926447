import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image/withIEPolyfill";

function BannerSection() {
  const { banner, gotradeIcon } = useStaticQuery(graphql`
    query BannerSectionQuery {
      banner: file(relativePath: { eq: "banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gotradeIcon: file(relativePath: { eq: "gotrade-green.png" }) {
        childImageSharp {
          fixed(width: 170, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <section className={`flex flex-col-reverse justify-between pb-10 lg:flex-row lg:items-center`}>
      <div className={`flex-1 flex flex-col text-left max-w-sm mt-4 lg:mt-0`}>
        <h1 className="pb-4">Investing,<br/>Done Right.</h1>
        <p className="subtitle mb-6">
          TR8 is on a mission to unlock wealth generation opportunities for all mankind.
        </p>
        <p className="our-products mb-3">Our products</p>
        <a href="https://www.heygotrade.com">
            <Image
                fixed={gotradeIcon.childImageSharp.fixed}
                objectFit="contain"
                objectPosition="50% 50%"
                alt="Gotrade banner"
            />
        </a>
      </div>
      <span className="w-4"></span>
      <Image
        className="mock-phone flex-initial"
        fluid={banner.childImageSharp.fluid}
        objectFit="contain"
        objectPosition="50% 50%"
        alt="Gotrade banner"
      />
    </section>
  );
}

export default BannerSection;