/* eslint-disable no-unused-vars */
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

function SEO({
  description,
  meta,
  keywords,
  title,
  isIndexable,
}) {
  const { site } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          tagline
          author
          image
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = `${site.siteMetadata.title} | ${site.siteMetadata.tagline}`;

  return (
    <Helmet
      title={metaTitle}
      titleTemplate={metaTitle}
      description={metaDescription}
    >
      <meta name="image" content={site.siteMetadata.image} />
      <meta name="description" content={metaDescription} />
      {keywords && (<meta name="keywords" content={keywords.join(', ')} />)}

      <meta name="description" content={metaDescription} />
      <meta name="image" content={site.siteMetadata.image} />

      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={site.siteMetadata.image} />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={site.siteMetadata.image} />

      { !isIndexable && (<meta name="robots" content="noindex, nofollow, noarchive" />) }
      { !isIndexable && (<meta name="googlebot" content="noindex" />) }
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  keywords: [],
  meta: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
  isIndexable: PropTypes.bool,
};

export default SEO;
