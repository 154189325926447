import React from "react";

import LinkIconFacebook from '../images/linkicon-facebook.svg';
import LinkIconInstagram from '../images/linkicon-instagram.svg';
import LinkIconLinkedin from '../images/linkicon-linkedin.svg';

// eslint-disable-next-line no-unused-vars
const SocialMediaSection = () => (
  <div className="mb-4 lg:mb-0">
    <a href="https://www.facebook.com"><img className="inline-block mr-4" src={LinkIconFacebook} alt="Facebook link"/></a>
    <a href="https://www.instagram.com"><img className="inline-block mr-4" src={LinkIconInstagram} alt="Instagram link"/></a>
    <a href="https://www.linkedin.com"><img className="inline-block mr-4" src={LinkIconLinkedin} alt="Linkedin link"/></a>
  </div>
);

function Footer() {
  return (
    <footer>
      <nav className="flex flex-col-reverse lg:flex-row justify-between items-center max-w-custom p-6 lg:p-4 mx-auto text-sm">
        <p className="text-white text-center mx-4 lg:mx-0 mb-2 lg:mb-0 lg:text-left">
          The TR8 Group consists of TR8 INC, TR8 SECURITIES INC and TR8 TECHNOLOGIES PTE LTD.
        </p>
        {/* <SocialMediaSection /> */}
      </nav>
    </footer>
  );
}

export default Footer;