import Image from "gatsby-image/withIEPolyfill";
import React from "react";

import { graphql, useStaticQuery } from "gatsby";

function Header() {
  const { logo } = useStaticQuery(graphql`
    query SiteTitleQuery {
      logo: file(relativePath: { eq: "tr8-icon.png" }) {
        childImageSharp {
          fixed(width: 150, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <header>
      <div className="flex flex-wrap items-center justify-between max-w-custom pt-4 px-4 mx-auto md:pt-8 md:px-8">
        <Image
          className="flex-none"
          fixed={logo.childImageSharp.fixed}
          objectFit="contain"
          alt="Logo"
        />
      </div>
    </header>
  );
}

export default Header;
