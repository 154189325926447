import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import BannerSection from "../components/banner-section";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[]}
        title="TR8"
      />

      <section>
        <BannerSection />
      </section>
    </Layout>
  );
}

export default IndexPage;
